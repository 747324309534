import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { morphism } from 'morphism';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="ResourcePage"
    dataName={'datoCmsResourcePage'}
  />
);

export const query = graphql`
  query DatoResourcePage($locale: String, $id: String) {
    datoCmsResourcePage(locale: $locale, id: { eq: $id }) {
      ...ResourcePage
    }
  }
`;

export default function ResourcePage({ data, pageContext }: any) {
  const screenSize = useScreenSize();

  const doc = data.datoCmsResourcePage;
  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
    screenSize,
  });

  if (!data.datoCmsResourcePage) return <></>;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="ResourcePage">
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
}
